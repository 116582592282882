import React, { createContext } from 'react';
import { Main } from '../components/Main';

export const ThemeContext = createContext('Dark');

const App = () => {
  return (
    <ThemeContext.Provider value="Dark">
      <Main />
    </ThemeContext.Provider>
  );
};

export default App;
