import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Episode } from './podcastSlice';

import { AppThunk } from '../../app/store';

interface PlayerState {
  isPlaying: boolean;
  isReady: boolean;
  showDrawer: boolean;
  drawerContent: 'description' | 'sharing' | 'subscribe';
  currentEpisode: number | null;
}

const playerInitialState: PlayerState = {
  isPlaying: false,
  isReady: false,
  showDrawer: false,
  drawerContent: 'description',
  currentEpisode: null,
};

const player = createSlice({
  name: 'player',
  initialState: playerInitialState,
  reducers: {
    setIsPlaying(state, { payload }: PayloadAction<boolean>) {
      state.isPlaying = payload;
    },
    setCurrentEpisode(state, { payload }: PayloadAction<number>) {
      state.currentEpisode = payload;
    },
    setIsReady(state, { payload }: PayloadAction<boolean>) {
      state.isReady = payload;
    },
    setShowDrawer(state, { payload }: PayloadAction<boolean>) {
      state.showDrawer = payload;
    },
    setDrawerContent(
      state,
      { payload }: PayloadAction<'description' | 'sharing' | 'subscribe'>
    ) {
      state.drawerContent = payload;
    },
  },
});

export const {
  setCurrentEpisode,
  setIsPlaying,
  setIsReady,
  setShowDrawer,
  setDrawerContent,
} = player.actions;

export default player.reducer;

export const playEpisodeFromPlaylist = (
  position: number
): AppThunk => async dispatch => {
  dispatch(setIsPlaying(false));
  dispatch(setCurrentEpisode(position));
  dispatch(setIsPlaying(true));
};

export const togglePlay = (play: boolean): AppThunk => async dispatch => {
  dispatch(setIsPlaying(play));
};

export const pauseEpisode = (): AppThunk => async dispatch => {
  dispatch(setIsPlaying(false));
};

export const setStartingEpisode = (
  episodes: Episode[],
  start: string | null
): AppThunk => async dispatch => {
  if (!start) {
    dispatch(setCurrentEpisode(0));
  } else {
    dispatch(
      setCurrentEpisode(episodes.findIndex(episode => episode.guid === start))
    );
  }
};
