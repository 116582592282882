import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playEpisodeFromPlaylist } from '../player/playerSlice';

import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';

import { RootState } from '../../app/rootReducer';

import clsx from 'clsx';

import styles from './Playlist.module.scss';

export const Playlist = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { episodesByPosition } = useSelector(
    (state: RootState) => state.podcast
  );
  const { currentEpisode, isPlaying } = useSelector(
    (state: RootState) => state.player
  );

  function clickHandler(position: number) {
    dispatch(playEpisodeFromPlaylist(position));
  }

  const renderedEpisodes = (
    <ol className={styles.list}>
      {episodesByPosition.map((episode, index) => (
        <li
          key={episode.position}
          className={clsx(
            styles.listItem,
            currentEpisode === index && styles.listItemActive
          )}
          onClick={() => clickHandler(index)}
        >
          {currentEpisode === index && isPlaying ? <PauseIcon /> : <PlayIcon />}
          {episode.title}
        </li>
      ))}
    </ol>
  );

  useEffect(() => {
    if (!isScrolled && currentEpisode) {
      if (container.current) {
        const current: HTMLLIElement | null = container.current.querySelector(
          `li:nth-child(${currentEpisode + 1})`
        );

        if (current) {
          container.current.scrollTop = current.offsetTop;
          setIsScrolled(true);
        }
      }
    }
  }, [currentEpisode]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Playlist</p>
      <div className={styles.listContainer} ref={container}>
        {renderedEpisodes}
      </div>
    </div>
  );
};
