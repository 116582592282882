import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import './index.css';
import * as serviceWorker from './serviceWorker';

const render = () => {
  const App = require('./app/App').default;

  const container = window.player?.container
    ? document.querySelector(window.player.container)
    : document.getElementById('root');

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    container
  );
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.SERVICE_ENV === 'local' && module.hot) {
  module.hot.accept('./app/App', render);
}
