import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../components/Loader';
import { setConfig, Config } from '../features/player/configSlice';
import { fetchPodcast } from '../features/player/podcastSlice';
import { setStartingEpisode } from '../features/player/playerSlice';
import { Player } from '../features/player/Player';
import { Playlist } from '../features/playlist/Playlist';
import { RootState } from '../app/rootReducer';
import { ThemeContext } from '../app/App';
import clsx from 'clsx';

import styles from './Main.module.scss';

const height = 140;

interface Object {
  [key: string]: any;
}

declare global {
  interface Window {
    player: Config;
  }
}

function getConfig() {
  let config: Object = {};
  if (window.player) {
    config = window.player;
  } else {
    window.location.search
      .substring(1)
      .split('&')
      .forEach(param => {
        const [key, value] = param.split('=');
        config[key] = value;
      });
  }
  return config as Config;
}

export const Main = () => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const {
    feed,
    playlist,
    start,
    cover,
    background,
    isReady: isConfigReady,
    error: configError,
  } = useSelector((state: RootState) => state.config);
  const { podcast, episodesByPosition, error: podcastError } = useSelector(
    (state: RootState) => state.podcast
  );
  const { currentEpisode, isReady: isPlayerReady } = useSelector(
    (state: RootState) => state.player
  );

  useEffect(() => {
    if (podcast && currentEpisode !== null) {
      const episode = episodesByPosition[currentEpisode];
      const metas = [
        ['og:title', episode.title],
        ['og:description', episode.description],
        ['og:image', podcast.image],
        ['og:video', episode.url],
        ['og:video:secure_url', episode.url],
        ['og:video:height', (playlist ? height * 2 : height).toString()],
        ['twitter:title', episode.title],
        ['twitter:description', episode.description],
        ['twitter:image', podcast.image],
        ['twitter:player:height', (playlist ? height * 2 : height).toString()],
      ];

      metas.forEach(([property, content]: string[]) => {
        const meta = document.querySelector(`meta[property="${property}"]`);

        if (meta) {
          meta.setAttribute('content', content);
        }
      });

      document.title = episodesByPosition[currentEpisode].title;
    }
  }, [episodesByPosition, currentEpisode]);

  useEffect(() => {
    if (!isConfigReady) {
      dispatch(setConfig(getConfig()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (feed) {
      dispatch(fetchPodcast(feed));
    }
  }, [dispatch, feed]);

  useEffect(() => {
    if (episodesByPosition.length) {
      dispatch(setStartingEpisode(episodesByPosition, start));
    }
  }, [dispatch, episodesByPosition]);

  let content = null;

  if (configError || podcastError) {
    content = <p className={styles.error}>{configError || podcastError}</p>;
  } else if (podcast && currentEpisode !== null) {
    let style: Object = {};
    if (cover) {
      style.backgroundImage = `url(${podcast?.image})`;
    }
    if (background) {
      style.backgroundColor = background;
    }
    content = (
      <div className={clsx(styles.content, cover && styles.contentShadow)}>
        <Player podcast={podcast} />
        {playlist && <Playlist />}
        <div
          className={clsx(
            styles.backdrop,
            styles[`backdropCover${theme}`],
            cover && styles.backdropCover
          )}
          style={style}
        ></div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.container, playlist && styles.containerDouble)}>
      <div
        className={clsx(
          styles.loader,
          (isPlayerReady || configError || podcastError) && styles.loaderReady
        )}
      >
        <Loader />
      </div>
      {content}
    </div>
  );
};
