import { combineReducers } from '@reduxjs/toolkit';
import configReducer from '../features/player/configSlice';
import podcastReducer from '../features/player/podcastSlice';
import playerReducer from '../features/player/playerSlice';

const rootReducer = combineReducers({
  config: configReducer,
  podcast: podcastReducer,
  player: playerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
