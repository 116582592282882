import React, { useContext } from 'react';

import { ThemeContext } from '../../app/App';

import { ProgressBar } from '../../components/ProgressBar';

import styles from './Timeline.module.scss';
import clsx from 'clsx';

interface Props {
  progress: number;
  duration: number;
  updateTime: (time: number) => void;
}

export const Timeline = ({ progress, duration, updateTime }: Props) => {
  const theme = useContext(ThemeContext);

  function formatTime(time: number) {
    const rounded = Math.round(time);
    const minutes = rounded / 60;
    const seconds = rounded % 60;

    return [minutes, seconds]
      .map(item => `0${String(Math.floor(item))}`.slice(-2))
      .join(':');
  }

  return (
    <div className={clsx(styles.container, styles[`container${theme}`])}>
      <div className={styles.time}>
        <p>{formatTime(progress)}</p>
        <p>{formatTime(duration)}</p>
      </div>
      <div className={styles.progress}>
        <ProgressBar
          value={(duration && (100 * progress) / duration) || 0}
          updateProgress={updateTime}
        />
      </div>
    </div>
  );
};
