import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../app/App';
import { RootState } from '../../app/rootReducer';

import { ReactComponent as RssIcon } from '../../assets/rss.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import appleImg from '../../assets/apple.webp';
import googleImg from '../../assets/google.webp';

import styles from './Subscribe.module.scss';
import clsx from 'clsx';

export const Subscribe = () => {
  const theme = useContext(ThemeContext);

  const { feed, apple, google } = useSelector(
    (state: RootState) => state.config
  );
  const { podcast, episodesByPosition } = useSelector(
    (state: RootState) => state.podcast
  );
  const { currentEpisode } = useSelector((state: RootState) => state.player);

  return (
    <div className={clsx(styles.container, styles[`container${theme}`])}>
      <p className={styles.title}>Subscribe</p>
      <ul className={styles.list}>
        <li>
          <a title={`${podcast!.title}'s rss`} href={feed!} target="_blank">
            <RssIcon />
          </a>
        </li>
        {apple && (
          <li>
            <a
              title="Apple Podcasts"
              href={`https://podcasts.apple.com/podcast/${apple}`}
              target="_blank"
            >
              <img src={appleImg} alt="Apple Podcasts" />
            </a>
          </li>
        )}
        {google && (
          <li>
            <a
              title="Google Podcasts"
              href={`https://podcasts.google.com/?feed=${google}`}
              target="_blank"
            >
              <img src={googleImg} alt="Google podcast" />
            </a>
          </li>
        )}
        <li>
          <a
            title={`Download ${episodesByPosition[currentEpisode!].title}`}
            href={episodesByPosition[currentEpisode!].url}
            download
            target="_blank"
          >
            <DownloadIcon />
          </a>
        </li>
      </ul>
    </div>
  );
};
