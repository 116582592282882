import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../app/store';

export interface Config {
  container: string | null;
  feed: string | null;
  apple: string | null;
  google: string | null;
  playlist: boolean;
  start: string | null;
  cover: boolean;
  primary: string | null;
  background: string | null;
}

interface ConfigState {
  container: string | null;
  feed: string | null;
  apple: string | null;
  google: string | null;
  playlist: boolean;
  start: string | null;
  cover: boolean;
  primary: string | null;
  background: string | null;
  isReady: boolean;
  error: string | null;
}

const configInitialState: ConfigState = {
  container: null,
  feed: null,
  apple: null,
  google: null,
  playlist: true,
  start: null,
  cover: false,
  primary: null,
  background: null,
  isReady: false,
  error: null,
};

const config = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {
    setConfigSuccess(state, { payload }: PayloadAction<Config>) {
      state.feed = payload.feed;
      state.apple = payload.apple;
      state.google = payload.google;
      if (typeof payload.playlist !== 'undefined') {
        state.playlist = Boolean(Number(payload.playlist));
      }
      if (typeof payload.cover !== 'undefined') {
        state.cover = Boolean(Number(payload.cover));
      }
      state.background = payload.background;
      state.primary = payload.primary;
      state.start = payload.start;
      state.isReady = true;
      state.error = null;
    },
    setConfigFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
  },
});

export const { setConfigSuccess, setConfigFailure } = config.actions;

export default config.reducer;

export const setConfig = (config: Config): AppThunk => async dispatch => {
  if (config.feed && config.feed.length) {
    dispatch(setConfigSuccess(config));
  } else {
    dispatch(setConfigFailure(new Error('Podcast feed is missing').toString()));
  }
};
