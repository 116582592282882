import React, { useContext } from 'react';

import { ThemeContext } from '../../app/App';

import { ReactComponent as NotesIcon } from '../../assets/notes.svg';
import { ReactComponent as RssIcon } from '../../assets/rss.svg';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';

import styles from './Actions.module.scss';
import clsx from 'clsx';

interface Props {
  clickHandler: (type: 'description' | 'sharing' | 'subscribe') => void;
  className?: string;
}

export const Actions = ({ clickHandler, className }: Props) => {
  const theme = useContext(ThemeContext);

  const renderedActions = [
    {
      label: 'Description',
      type: 'description' as 'description' | 'sharing' | 'subscribe',
      icon: <NotesIcon />,
    },
    {
      label: 'Subscribe',
      type: 'subscribe' as 'description' | 'sharing' | 'subscribe',
      icon: <RssIcon />,
    },
    {
      label: 'Sharing',
      type: 'sharing' as 'description' | 'sharing' | 'subscribe',
      icon: <ShareIcon />,
    },
  ].map(({ label, type, icon }) => {
    return (
      <li key={type}>
        <button
          onClick={() => clickHandler(type)}
          className={clsx(styles.action, styles[type])}
        >
          {icon}
          <span>{label}</span>
        </button>
      </li>
    );
  });

  return (
    <ul
      className={clsx(styles.container, styles[`container${theme}`], className)}
    >
      {renderedActions}
    </ul>
  );
};
