import React, { MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './Button.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  clickHandler: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const Button = ({ children, clickHandler, className }: Props) => {
  return (
    <button className={clsx(styles.button, className)} onClick={clickHandler}>
      {children}
    </button>
  );
};
