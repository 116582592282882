import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowDrawer } from '../features/player/playerSlice';
import { RootState } from '../app/rootReducer';

import { ReactComponent as CloseIcon } from '../assets/close.svg';

import styles from './Drawer.module.scss';
import clsx from 'clsx';

interface Props {
  children: ReactNode | ReactNode[];
}

export const Drawer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { showDrawer } = useSelector((state: RootState) => state.player);

  const closeHandler = () => {
    dispatch(setShowDrawer(false));
  };

  return (
    <div
      className={clsx(styles.container, showDrawer && styles.containerActive)}
    >
      <button className={styles.closeBtn} onClick={closeHandler}>
        <CloseIcon />
      </button>
      {children}
    </div>
  );
};
