import React, { useContext, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';

import { Podcast } from './podcastSlice';
import {
  togglePlay,
  setIsReady,
  setShowDrawer,
  setDrawerContent,
} from './playerSlice';
import { ThemeContext } from '../../app/App';
import { RootState } from '../../app/rootReducer';

import { Button } from '../../components/Button';
import { Drawer } from '../../components/Drawer';
import { Timeline } from './Timeline';
import { SoundControl } from './SoundControl';
import { Actions } from './Actions';
import { Description } from '../description/Description';
import { Sharing } from '../sharing/Sharing';
import { Subscribe } from '../subscribe/Subscribe';

import styles from './Player.module.scss';
import clsx from 'clsx';

interface Props {
  podcast: Podcast;
}

export const Player = ({ podcast }: Props) => {
  const dispatch = useDispatch();
  const player = useRef<HTMLAudioElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const theme = useContext(ThemeContext);
  const { episodesByPosition } = useSelector(
    (state: RootState) => state.podcast
  );
  const { currentEpisode, isPlaying, showDrawer, drawerContent } = useSelector(
    (state: RootState) => state.player
  );

  const drawer = {
    description: <Description />,
    sharing: <Sharing />,
    subscribe: <Subscribe />,
  };

  function onTogglePlay() {
    dispatch(togglePlay(!isPlaying));
  }

  useEffect(() => {
    player?.current?.addEventListener('durationchange', onDurationChange);
    player?.current?.addEventListener('volumechange', onVolumeChange);
    player?.current?.addEventListener('play', watchProgress);
    if (image?.current?.complete) {
      dispatch(setIsReady(true));
    } else {
      image?.current?.addEventListener('load', () => {
        dispatch(setIsReady(true));
      });
    }

    return () => {
      player?.current?.removeEventListener('durationchange', onDurationChange);
      player?.current?.removeEventListener('volumechange', onVolumeChange);
      player?.current?.removeEventListener('play', watchProgress);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      player?.current?.play();
    } else {
      player?.current?.pause();
    }
  }, [isPlaying, currentEpisode]);

  function onDurationChange() {
    player.current && setDuration(player.current.duration);
  }

  function onVolumeChange() {
    player.current && setVolume(player.current.volume);
  }

  function watchProgress() {
    player.current && setProgress(player.current.currentTime);

    if (!player?.current?.paused) {
      requestAnimationFrame(watchProgress);
    }
  }

  function updateTime(time: number) {
    if (player.current) {
      player.current.currentTime = (time * player.current.duration) / 100;
      setProgress(player.current.currentTime);
    }
  }

  function updateVolume(volume: number) {
    if (player.current) {
      player.current.volume = volume / 100;
    }
  }

  function actionsClickHandler(page: 'description' | 'sharing' | 'subscribe') {
    dispatch(setDrawerContent(page));
    dispatch(setShowDrawer(true));
  }

  return (
    <div className={clsx(styles.player, styles[`player${theme}`])}>
      <img
        src={podcast.image}
        alt={podcast.title}
        className={styles.image}
        ref={image}
      />
      <div className={styles.main}>
        <div
          className={clsx(styles.controls, showDrawer && styles.drawerActive)}
        >
          <div className={styles.head}>
            <p className={styles.title}>{podcast.title}</p>
            <p className={styles.episodeTitle}>
              {episodesByPosition[currentEpisode as number].title}
            </p>
            <Actions
              className={styles.actions}
              clickHandler={actionsClickHandler}
            />
          </div>
          <div className={styles.playBtn}>
            <Button clickHandler={onTogglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </Button>
          </div>
          <SoundControl
            className={styles.soundControl}
            volume={volume}
            updateVolume={updateVolume}
          />
          <div className={styles.footer}>
            <Timeline
              progress={progress}
              duration={duration}
              updateTime={updateTime}
            />
          </div>
        </div>
        <Drawer>{drawer[drawerContent]}</Drawer>
        <audio
          ref={player}
          preload="metadata"
          src={episodesByPosition[currentEpisode as number].url}
        />
      </div>
    </div>
  );
};
