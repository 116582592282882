import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../app/rootReducer';

import styles from './ProgressBar.module.scss';
import clsx from 'clsx';

interface Object {
  [key: string]: any;
}
interface Props {
  value: number;
  forceHover?: boolean;
  updateProgress: (progress: number) => void;
}

export const ProgressBar = ({ value, forceHover, updateProgress }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  let leftTrackBounds = 0;
  let rightTrackBounds = 0;
  let trackLength = 0;

  const {
    primary,
  } = useSelector((state: RootState) => state.config);

  function mouseEnterHandler() {
    setIsHover(true);
  }

  function mouseLeaveHandler() {
    setIsHover(false);
  }

  function mouseUpHandler() {
    setIsActive(false);
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  }

  function mouseDownHandler(event: React.MouseEvent) {
    setIsActive(true);
    const bounds = event.currentTarget.getBoundingClientRect();
    leftTrackBounds = bounds.left;
    rightTrackBounds = bounds.right;
    trackLength = bounds.width;
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
    getProgress(event.clientX);
  }

  function mouseMoveHandler(event: MouseEvent) {
    getProgress(event.clientX);
  }

  function getProgress(mouseX: number) {
    let value: number = 0;
    if (leftTrackBounds > mouseX) {
      value = 0;
    } else if (rightTrackBounds < mouseX) {
      value = 100;
    } else {
      value = (100 * (mouseX - leftTrackBounds)) / trackLength;
    }
    updateProgress(value);
  }

  const containerActive = (isHover || isActive || forceHover);

  const filledStyle: Object = { left: `-${100 - value}%` };
  if (containerActive) {
    filledStyle.backgroundColor = primary;
  }

  return (
    <div
      className={clsx(
        styles.container,
        containerActive && styles.containerActive
      )}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseDown={mouseDownHandler}
    >
      <div className={styles.background}>
        <div
          className={styles.filled}
          style={filledStyle}
        ></div>
      </div>
      <div className={styles.seekerTrack}>
        <div className={styles.seeker} style={{ left: `${value}%` }}></div>
      </div>
    </div>
  );
};
