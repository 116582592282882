import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ThemeContext } from '../../app/App';
import { RootState } from '../../app/rootReducer';

import { Scroll } from '../../components/Scroll';

import styles from './Description.module.scss';
import clsx from 'clsx';

export const Description = () => {
  const theme = useContext(ThemeContext);

  const { episodesByPosition } = useSelector(
    (state: RootState) => state.podcast
  );
  const { currentEpisode } = useSelector((state: RootState) => state.player);

  const episode = episodesByPosition[currentEpisode!];

  return (
    <div className={clsx(styles.container, styles[`container${theme}`])}>
      <p className={styles.title}>{episode.title}</p>
      <Scroll className={styles.scroll}>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: episode.description }}
        />
      </Scroll>
    </div>
  );
};
