import React, { useContext } from 'react';
import { ThemeContext } from '../../app/App';

import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';

import styles from './Sharing.module.scss';
import clsx from 'clsx';

export const Sharing = () => {
  const theme = useContext(ThemeContext);

  return (
    <div className={clsx(styles.container, styles[`container${theme}`])}>
      <p className={styles.title}>Share</p>
      <ul className={styles.list}>
        <li>
          <a
            title="Share on Facebook"
            href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${window.location.href}&display=popup&ref=plugin&src=share_button`}
            target="_blank"
          >
            <FacebookIcon />
          </a>
        </li>
        <li>
          <a
            title="Share on Twitter"
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            target="_blank"
          >
            <TwitterIcon />
          </a>
        </li>
      </ul>
    </div>
  );
};
