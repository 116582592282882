import React, { useContext, useState } from 'react';

import { ReactComponent as VolumeDownIcon } from '../../assets/volume_down.svg';
import { ReactComponent as VolumeMuteIcon } from '../../assets/volume_mute.svg';
import { ReactComponent as VolumeUpIcon } from '../../assets/volume_up.svg';

import { ThemeContext } from '../../app/App';

import { ProgressBar } from '../../components/ProgressBar';

import styles from './SoundControl.module.scss';
import clsx from 'clsx';

interface Props {
  volume: number;
  updateVolume: (volume: number) => void;
  className?: string;
}

export const SoundControl = ({ volume, updateVolume, className }: Props) => {
  const theme = useContext(ThemeContext);

  const [isHover, setIsHover] = useState(false);
  const [lastSetVolume, setLastSetVolume] = useState(volume);

  function mouseEnterHandler() {
    setIsHover(true);
  }

  function mouseLeaveHandler() {
    setIsHover(false);
  }

  function clickHandler() {
    if (volume === 0) {
      updateVolume(lastSetVolume * 100);
    } else {
      setLastSetVolume(volume);
      updateVolume(0);
    }
  }

  return (
    <div
      className={clsx(styles.container, styles[`container${theme}`], className)}
    >
      <div
        className={styles.volumeIcon}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        onClick={clickHandler}
      >
        {volume === 0 && <VolumeMuteIcon />}
        {volume > 0 && volume < 0.5 && <VolumeDownIcon />}
        {volume >= 0.5 && <VolumeUpIcon />}
      </div>
      <div className={styles.progress}>
        <ProgressBar
          value={100 * volume}
          forceHover={isHover}
          updateProgress={updateVolume}
        />
      </div>
    </div>
  );
};
