import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../app/store';

export interface Podcast {
  feed: string;
  title: string;
  description: string;
  image: string;
}

export interface Episode {
  position: number;
  title: string;
  description: string;
  url: string;
  guid: string;
}

interface parsePodcastResponse {
  podcast: Podcast;
  episodesByPosition: Episode[];
}

interface PodcastState {
  podcast: Podcast | null;
  episodesByPosition: Episode[];
  isLoading: boolean;
  error: string | null;
}

const podcastInitialState: PodcastState = {
  podcast: null,
  episodesByPosition: [],
  isLoading: false,
  error: null,
};

function parseEpisodes(episodes: Element[]): Episode[] {
  const parsedEpisodes: Episode[] = [];

  episodes.forEach((episode, index) => {
    const title = episode.querySelector('title')?.innerHTML;
    const description = episode.querySelector('description')?.innerHTML;
    const url = episode.querySelector('enclosure')?.getAttribute('url');
    const guid = episode.querySelector('guid')?.innerHTML;

    if (title && description && url && guid) {
      parsedEpisodes.push({
        position: index + 1,
        title,
        description,
        url,
        guid,
      });
    }
  });

  if (parsedEpisodes.length) {
    return parsedEpisodes;
  } else {
    throw new Error('No episodes found');
  }
}

function parsePodcast(podcast: string, feed: string): parsePodcastResponse {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(podcast, 'text/xml');

  const title = xmlDoc.querySelector('title')?.innerHTML;
  const description = xmlDoc.querySelector('description')?.innerHTML;
  const image = xmlDoc.querySelector('image url')?.innerHTML;

  if (title && description && image) {
    return {
      podcast: {
        feed,
        title,
        description,
        image,
      },
      episodesByPosition: parseEpisodes([
        ...((xmlDoc.querySelectorAll('item') as unknown) as Element[]),
      ]),
    };
  } else {
    throw new Error('Mandatory podcast properties missing');
  }
}

function startLoading(state: PodcastState) {
  state.isLoading = true;
}

function loadingFailed(state: PodcastState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

export const podcast = createSlice({
  name: 'podcast',
  initialState: podcastInitialState,
  reducers: {
    getPodcastStart: startLoading,
    getPodcastSuccess(state, { payload }) {
      state.podcast = payload.podcast;
      state.episodesByPosition = payload.episodesByPosition;
      state.isLoading = false;
      state.error = null;
    },
    getPodcastFailure: loadingFailed,
  },
});

export const {
  getPodcastStart,
  getPodcastSuccess,
  getPodcastFailure,
} = podcast.actions;

export default podcast.reducer;

async function getPodcast(url: string): Promise<parsePodcastResponse> {
  try {
    const data = await fetch(url).then(response => {
      if (!response.ok) {
        throw response;
      }

      return response.text();
    });

    return parsePodcast(data, url);
  } catch (err) {
    throw err;
  }
}

export const fetchPodcast = (rss: string): AppThunk => async dispatch => {
  try {
    dispatch(getPodcastStart());
    const podcast = await getPodcast(rss);
    dispatch(getPodcastSuccess(podcast));
  } catch (err) {
    dispatch(getPodcastFailure(err.toString()));
  }
};
